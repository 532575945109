<div class="work-order-edit-container p-10">

  <mat-tab-group [(selectedIndex)]="viewModel.selectedIndex">

    <mat-tab label="Dati generali">

      <ctm-general-data #generalDataComponent *ngIf="generalDataConfiguration"
        [configuration]="generalDataConfiguration" [creaPackagingButtonConfiguration]="creaPackagingButtonConfiguration"
        (onSubmit)="submit($event)"></ctm-general-data>

      <!-- In input mi aspetto la configurazione del button crea Packaging -->


    </mat-tab>

    <mat-tab [label]="(isQuotation ? 'RowQuotation' : 'RowWorkOrder') | translate "
      *ngIf="viewModel.masterDetail && movementManagerConfiguration && movementManagerConfiguration.visible">

      <ctm-movement-manager #movementManagerComponent
        [configuration]="movementManagerConfiguration"></ctm-movement-manager>

    </mat-tab>

    <ng-container *ngIf="viewModel.workOrderRowHandlingEnabled">

      <mat-tab [label]="'Movimentazioni/Ordini'" *ngIf="!isQuotation">

        <ctm-work-order-handlings [configuration]="workOrderHandlingsConfiguration"></ctm-work-order-handlings>

      </mat-tab>

      <mat-tab [label]="'Riepilogo'">

        <ctm-work-order-summary></ctm-work-order-summary>

      </mat-tab>

      <mat-tab [label]="'Magazzini/Packaging'">

        <ctm-work-order-location [configuration]="workOrderLocationConfiguration"></ctm-work-order-location>

      </mat-tab>

    </ng-container>

    <ng-container *ngIf="viewModel.masterDetail">

      <mat-tab [label]="'Gestione documenti'">

        <ctm-work-order-documents [configuration]="workOrderDocumentsConfiguration"></ctm-work-order-documents>

      </mat-tab>
    </ng-container>

  </mat-tab-group>
  <div *ngIf="schedulerListConfiguration" class="ct-manager-master-detail-container">

    <app-scheduler-list *ngIf="schedulerListConfiguration"
      [schedulerListConfiguration]="schedulerListConfiguration"></app-scheduler-list>


  </div>

</div>
<ng-template #headerActions>

  <app-lang-download-file [configuration]="langDownloadFileConfiguration"
    (onDownload)="downloadPdf($event)"></app-lang-download-file>


</ng-template>

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CtDatatableActionCustomConfiguration, CtModelConfiguration } from "@ctsolution/ct-framework";
import { DEFAULT_DATATABLE_CONFIGURATION } from "../../../_core/constants";
import { RoleService } from "../../../_core/lib/role.service";
import { UserWorkingHoursWizardService } from "../user-working-hours-wizard/user-working-hours-wizard.service";
import { UserWorkingHoursRouteData } from "../../../_core/route-data/user-working-hours.route-data";
import { ActivatedRoute } from "@angular/router";
import { UserWorkingHoursExportService } from "../user-working-hours-export/user-working-hours-export.service";
import { FileService } from '../../../_core/lib/file.service';
import { FileGet } from '../../../_core/classes/file-get';

@Component({
  selector: 'app-user-working-hours-list',
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>
    <ng-template #headerActions>
      <button
        (click)="exportExcel()"
        mat-mini-fab
        color="accent">
        <mat-icon>downloading</mat-icon>
      </button>
    </ng-template>

 
    
    <ng-template #downloadAttachmentButton let-value="value">

        <button
          mat-icon-button
          [color]="(value.FilesCount ?? 0) <= 0 ? 'accent' : 'primary'"
          [disabled]="(value.FilesCount ?? 0) <= 0"
          [matMenuTriggerFor]="menu">
          <mat-icon>photo_camera</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="download(value)" mat-menu-item>{{ value.Oid + '.zip'}}
          </button>
        </mat-menu>

    </ng-template>`
})
export class UserWorkingHoursListComponent {

  @ViewChild("headerActions") headerActions: TemplateRef<any> | null = null;
  configuration: CtModelConfiguration<UserWorkingHoursListComponent> | null = null;
  @ViewChild("downloadAttachmentButton") downloadAttachmentButton: TemplateRef<any> | null = null;

  constructor(
    private role: RoleService,
    private userWorkingHoursExportService: UserWorkingHoursExportService,
    private userWorkingHoursWizardService: UserWorkingHoursWizardService,
    private route: ActivatedRoute,
    private fileService: FileService) {

  }

  ngOnInit() {

    this.setup();

  }

  ngAfterViewInit() {

    this.route
      .data
      .subscribe(data => {

        data['headerActions'] = this.headerActions;
        data['urls'] = [];

      })

  }

  exportExcel() {

    this.userWorkingHoursExportService
      .openDialog();

  }

  private reload() {

    this.configuration = null;
    setTimeout(() => this.setup());

  }

  async setup() {

    const isAdministrator = await this.role.is.administrator();

    const datatableConfiguration = DEFAULT_DATATABLE_CONFIGURATION()
    .setCustomDatatableActionConfiguration(
        CtDatatableActionCustomConfiguration
          .create()
          .setTemplateOutlet(this.downloadAttachmentButton));


    this.configuration = CtModelConfiguration
      .create<UserWorkingHoursListComponent>()
      .setRouteData(UserWorkingHoursRouteData())
      .setCTDatatableConfiguration(datatableConfiguration)
      .setOnCreate(() => {

        this.userWorkingHoursWizardService
          .open()
          .beforeClosed()
          .subscribe(() => this.reload())

      })
      .setOnEdit(event => {

        this.userWorkingHoursWizardService
          .open(event)
          .beforeClosed()
          .subscribe(() => this.reload())

      })
      .setFieldsCustomTemplates([
        {
          prop: 'UserNameInfo',
          visible: isAdministrator
        }
      ])

  }

    download(value : any) {
  
       const endpoint = `/UserWorkingHours/GetDocumentsZip/${value.Oid}`;
       const fileName = `Zip_Foto_${value.Oid}`;
       const param : FileGet ={
         Endpoint: endpoint
       };
       this.fileService.downloadAttachmentByEndPoint(param,fileName)
  
    }
  

}
